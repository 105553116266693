import React from 'react';
import NaverMap from 'components/Map/NaverMap';

const HomePage = () => {
    return (
        <div>
            <NaverMap />
        </div>
    );
}

export default HomePage;